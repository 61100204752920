/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaWpp = (props) => {
  const [text, setText] = useState("INSCREVA SEU FILHO");
  const [link, setLink] = useState("12991269774");

  useEffect(() => {
    if(props.text) setText(props.text);
  }, [])

  useEffect(() => {
    if(props.link) setLink(props.link);
  }, [])

  return (
    <button id="ctaWpp">
      <a
        href={`http://wa.me/+55${link}?text=Quero%20mais%20informações%20sobre%20a%20escolinha%20de%20futebol`}
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={icon} alt="Ícone do Whatsapp" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
