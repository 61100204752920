import "./styles.css";
import { InstagramEmbed } from "react-social-media-embed";

import CTAWpp from "../../components/CTAWpp";

import header2_2 from "../../assets/header2_2.png";
import about2 from "../../assets/about2.png";
import stars from "../../assets/stars.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <figure>
            <img src={header2_2} alt="Logo Vem Pro Fut" />
          </figure>

          <h2 className="title">NOSSA METODOLOGIA</h2>

          <p className="text1">
            Dentro da nossa metodologia, entendemos o praticante como pessoa
            capaz de agir e refletir, por esse motivo as atividades propostas
            devem levá-lo a valorizar a vida de maneira participativa, a
            preocupação exagerada em formar campeões no esporte pode queimar as
            etapas do desenvolvimento da criança podendo comprometer seu futuro,{" "}
            <b>o nosso objetivo a ser atingido é formar campeões da vida.</b>{" "}
          </p>

          <h2 className="title">NOSSAS unidades</h2>
        </div>
      </article>

      <article className="about1_2">
        <div className="container">
          <p className="text">
            Dentro da nossa metodologia, entendemos o praticante como pessoa
            capaz de agir e refletir, por esse motivo as atividades propostas
            devem levá-lo a valorizar a vida de maneira participativa, a
            preocupação exagerada em formar campeões no esporte pode queimar as
            etapas do desenvolvimento da criança podendo comprometer seu futuro,
            o nosso objetivo a ser atingido é formar campeões da vida.
          </p>

          <h2 className="title">NOSSAs unidades</h2>

          <div className="gallery">
            <div className="item">
              <p className="top">unidade 1</p>

              <p className="middle">Palavra da Vida 1</p>

              <p className="bottom">
                <b>Segunda-feira e Quinta-feira:</b> 14:00h às 18:00h.
              </p>
            </div>

            <div className="item">
              <p className="top">unidade 2</p>

              <p className="middle">
                Quadra do rafinha <span>(bairro Caetetuba).</span>{" "}
              </p>

              <p className="bottom">
                <b>Terça-feira e Quarta-feira:</b> 17:00h às 18:00h - 18:00h às
                19:00h.
              </p>
            </div>

            <div className="item">
              <p className="top">unidade 3</p>

              <p className="middle">
                Arena Lello Sports <span>(breve inauguração).</span>{" "}
              </p>

              <p className="bottom">
                Estrada Municipal do Mingu, nº 1080 Bairro Rio Abaixo - Atibaia.
              </p>
            </div>
          </div>

          <CTAWpp text={"TRANSFORME SEU FILHO EM UM ATLETA DE SUCESSO!"} />
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="left">
            <figure className="stars">
              <img src={stars} alt="Stars" />
            </figure>

            <h2 className="title">DEPOIMENTOS DE CLIENTES</h2>

            <p className="text">
              Veja os depoimentos de alguns pais, após seus filhos conhecerem a
              nossa escolinha:
            </p>

            <figure className="mobile">
              <img src={about2} alt="Depoimentos de Clientes" />
            </figure>

            <CTAWpp />
          </div>

          <figure className="right">
            <img src={about2} alt="Depoimentos de Clientes" />
          </figure>
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <div className="left">
            <InstagramEmbed
              url="https://www.instagram.com/p/CtrMZ3agW_4/"
              width={328}
            />
          </div>

          <div className="right">
            <h2 className="title">FIQUE LIGADO EM NOSSo instagram</h2>

            <p className="text">
              Mantenha-se atualizado sobre nossos projetos e carreiras de
              sucesso alcançadas com muito esforço e dedicação!
            </p>

            <div className="mobile">
              <InstagramEmbed url="https://www.instagram.com/p/CtrMZ3agW_4/" />
            </div>

            <b>Fale conosco via WhatsApp:</b>

            <div className="cta">
              <CTAWpp text={"(12) 99126-9774"} />
              <CTAWpp text={"(12) 97812-8234"} link={"12978128234"} />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
