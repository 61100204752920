import "./styles.css";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import logo from "../../assets/logo_topo.png";
import header2_2 from "../../assets/header2_2.png";
import header2_3 from "../../assets/header2_3.png";
import header2_4 from "../../assets/header2_4.png";
import header2_5 from "../../assets/header2_5.png";
import header2_3Mobile from "../../assets/mobile/header2_3.png";
import header2_4Mobile from "../../assets/mobile/header2_4.png";
import header2_5Mobile from "../../assets/mobile/header2_5.png";
import header3_2 from "../../assets/header3_2.png";
import header4_1 from "../../assets/header4_1.png";
import header4_2 from "../../assets/header4_2.png";
import header4_3 from "../../assets/header4_3.png";
import header4_4 from "../../assets/header4_4.png";

import CTAWpp from "../../components/CTAWpp";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: header4_1,
  },
  {
    id: 2,
    image: header4_2,
  },
  {
    id: 3,
    image: header4_3,
  },
  {
    id: 4,
    image: header4_4,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <figure className="logo">
            <img src={logo} alt="Logo" />
          </figure>

          <h1 className="title">
            <span className="text1">
              Descobrindo <b>talentos</b>, formando
            </span>
            <span className="text2">campeões</span>
            <span className="text3">
              <b>VEM PRO FUT</b> - A jornada da sua futura estrela do futebol,
              começa aqui!
            </span>
          </h1>

          <CTAWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <figure>
            <img src={header2_2} alt="Logo Vem pro Fut" />
          </figure>

          <h2 className="title">Escolinha de Futebol e gestão esportiva</h2>

          <h3 className="subtitle">
            POR QUE ESCOLHER A LELLO SPORTS PARA TRANSFORMAR O SEU FILHO EM UM
            ATLETA DE SUCESSO?
          </h3>

          <div className="header_about">
            <figure className="header_about1">
              <img src={header2_3} alt="Sobre" />
            </figure>

            <figure className="header_about2">
              <img src={header2_4} alt="Sobre" />
            </figure>

            <figure className="header_about3">
              <img src={header2_5} alt="Sobre" />
            </figure>
          </div>
        </article>
      </section>

      <section className="mobile">
        <div className="container">
          <figure className="header_about1">
            <img src={header2_3Mobile} alt="Sobre" />
          </figure>

          <figure className="header_about2">
            <img src={header2_4Mobile} alt="Sobre" />
          </figure>

          <figure className="header_about3">
            <img src={header2_5Mobile} alt="Sobre" />
          </figure>
        </div>
      </section>

      <section className="header3">
        <div className="container">
          <CTAWpp text={"TRANSFORME SEU FILHO EM UM ATLETA DE SUCESSO!"} />
        </div>
      </section>

      <section className="header3_2">
        <div className="container">
          <div className="left">
            <h2 className="title">
              <span>PRESENÇA dO OLHEIRO DO SÃO PAULO</span> NA peneira realizada
              no 1º semestre do ano de 2023.
            </h2>

            <figure className="mobile">
              <img src={header3_2} alt="Olheiro" />
            </figure>

            <p className="text">
              Tivemos a nossa primeira avaliação técnica do ano de 2023, com a
              presença do Olheiro do São Paulo. Seguimos firmes com dedicação e
              profissionalismo!
            </p>

            <CTAWpp />
          </div>

          <figure className="right">
            <img src={header3_2} alt="Olheiro" />
          </figure>
        </div>
      </section>

      <section className="header4">
        <div className="container">
          <div className="left">
            <Swiper
              className="carousel"
              spaceBetween={0}
              slidesPerView={1}
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id}>
                    <figure>
                      <img
                        src={image}
                        alt={`Exemplo ${id} de Foto da Escolinha`}
                      />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <CTAWpp />
          </div>

          <div className="right">
            <h2 className="title">quem somos</h2>

            <p className="text1">
              Olá, somos da escolinha de futebol Vem pro Fut. Aqui seu filho
              aprende os conceitos básicos do futebol dentro de um ambiente
              saudável, competitivo e leal.
            </p>

            <h2 className="title">missão</h2>

            <p className="text2">
              Nossa missão é ensinar mais do que futebol, queremos ensinar
              alunos a serem bons cidadãos.
            </p>

            <CTAWpp />
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
