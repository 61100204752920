import React from "react";
import "./styles.css";

import logo from "../../assets/logo_rodape.png";
import footer1 from "../../assets/footer1.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <h2 className="title">formas de pagamento</h2>

          <h3 className="subtitle">Pix, crédito, débito e via app asaas.</h3>

          <figure>
            <img src={footer1} alt="Bandeiras de Cartão" />
          </figure>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <figure>
            <img src={logo} alt="Logo" />
          </figure>

          <p className="text">Lello Sports - Todos os direitos reservados</p>
        </div>
      </div>

        <div className="footer3">
          <div className="container">
            <p className="tel">
              <b>Telefone:</b> (12) 97812-8234
            </p>
            

            <p className="text2">
              Desenvolvido por{" "}
              <a
                href="https://magencia.com.br"
                target="_blank"
                rel="noreferrer"
              >
                M Agência Digital
              </a>
            </p>
          </div>
        </div>
    </footer>
  );
};

export default Footer;
